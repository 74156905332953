import { NgModule } from '@angular/core';
import { NotificationDispatcherService } from '../shared/notification/notification-dispatcher.service';
import { SharedModule } from '../shared/shared.module';
import { NotificationIconComponent } from './notification-icon/notifications-icon.component';
import { NotificationItemComponent } from './notification-item/notification-item.component';
import { NotificationListComponent } from './notification-list/notification-list.component';
import { NotificationNegotiationComponent } from './notification-negotiation.component';
import { NotificationOverlayComponent } from './notification-overlay/notification-overlay.component';
import { NotificationService } from '@app/notification/services/notification-service';

@NgModule({
	imports: [
		SharedModule
	],
	declarations: [
		NotificationNegotiationComponent,
		NotificationIconComponent,
		NotificationOverlayComponent,
		NotificationItemComponent,
		NotificationListComponent
	],
	exports: [
		NotificationNegotiationComponent,
		NotificationIconComponent,
		NotificationOverlayComponent
	],
	providers: [
		NotificationDispatcherService,
		NotificationService
	]
})
export class NotificationModule { }